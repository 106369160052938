<template>
    <div class="container border-container">
        <div class="search-container">
            <span class="list-title">家长列表</span>
            <el-select class="circular-bead-select search-item-input" filterable clearable v-model="search.student_id" placeholder="选择学生" @change="pageChange(1)">
                <el-option v-for="item of studentList" :label="item.name" :value="item.id" :key="item.id"/>
            </el-select>
        </div>
        <div class="table-container">
            <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
                :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
                <el-table-column label="孩子" min-width="120" :show-overflow-tooltip="true" align="center">
                    <template slot-scope="scope">
                        {{scope.row.student.name}}
                    </template>
                </el-table-column>
                <el-table-column label="身份" min-width="50" :show-overflow-tooltip="true" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.relation | relationFilter }}
                    </template>
                </el-table-column>
                <el-table-column label="用户名" min-width="120" :show-overflow-tooltip="true" align="center">
                    <template slot-scope="scope">
                        {{scope.row.user.name}}
                    </template>
                </el-table-column>
                <el-table-column label="电话" min-width="120" align="center" >
                    <template slot-scope="scope">
                        {{scope.row.user.phone || '-'}}
                    </template>
                </el-table-column>
                <el-table-column label="订阅状态" min-width="120" align="center" >
                    <template slot-scope="scope">
                        {{scope.row.expirationType | expirationTypeFilter}}
                    </template>
                </el-table-column>
                <el-table-column label="过期时间" min-width="160" align="center" >
                    <template slot-scope="scope">
                        {{scope.row.user.expiration_time | timeFilter(1)}}
                    </template>
                </el-table-column>
                <el-table-column label="是否开放" min-width="120" align="center" v-if="auth('parent.camera.authority.manage')">
                    <el-switch slot-scope="scope" :value="!!scope.row.flag_camera_authority" @change="handleOpenChange(scope.row)" :disabled='!auth("ADMIN") && !auth("SCHOOL")'/>
                </el-table-column>
                <el-table-column label="操作" min-width="150" align="center" v-if="auth('parent.camera.authority.manage') || auth('ADMIN')">
                    <template slot-scope="scope">
                        <template v-if="auth('ADMIN')">
                            <el-button class="mr-10" type="text" size="small" @click="handleDialogShow(scope.row.user)">
                                {{scope.row.expirationType === EXPIRATION_TYPE.UNSUBSCRIBED ? '新增' : '续期'}}
                            </el-button>
                            <template v-if="scope.row.expirationType == EXPIRATION_TYPE.SUBSCRIBED">
                                <el-button class="mr-10" type="text" size="small" @click="handleRefund(scope.row)">退款</el-button>
                                <el-button class="mr-10" type="text" size="small" @click="handleStop(scope.row)">停止</el-button>
                            </template>
                        </template>
                        <el-button class="mr-10" type="text" size="small" @click="song3tian(scope.row)" :disabled="!!scope.row.flag_is_give">
                            赠三天
                        </el-button>
                    </template>
                </el-table-column>
                <div class="" slot="empty">
                    <no-data></no-data>
                </div>
            </el-table>
        </div>
        <div class="page-container">
            <el-pagination layout="prev, pager, next" background
                :page-size="10" :pager-count="5"
                :current-page="currPage"
                @current-change="pageChange"
                :total="total">
            </el-pagination>
        </div>
        <el-dialog :title="selectItem.expiration_time > 0 ? '续期' : '新增'" width="504px" :visible.sync="dialogVisible" :before-close="handleDialogClose">
            <div class="dialog-content">
                <div class="vip-list">
                    <div class="vip-item" :class="{active: selectIndex === 1}" @click="selectIndex = 1">
                        <div class="item-title">一个月</div>
                    </div>
                    <div class="vip-item" :class="{active: selectIndex === 2}" @click="selectIndex = 2">
                        <div class="item-title">一个季度</div>
                    </div>
                    <div class="vip-item" :class="{active: selectIndex === 3}" @click="selectIndex = 3">
                        <div class="item-title">一年</div>
                    </div>
                    <div class="vip-item" :class="{active: selectIndex === 4}" @click="selectIndex = 4">
                        <div class="item-title">自定义</div>
                    </div>
                </div>
                <div v-if='selectIndex === 4' style="margin-top: 20px;">
                    <el-date-picker type="datetime" v-model="customTime" value-format="timestamp" placeholder="请选择到期时间" style="width: 100%" :picker-options="pickerOptions" default-time="23:59:59"/>
                </div>
            </div>
            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center">
                <el-button @click="handleDialogClose">取消</el-button>
                <el-button type="primary" @click="handleDialogConfirm">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Core from 'core';
import dayjs from 'dayjs'
import {relationFilter} from '@/core/filters/index'
const EXPIRATION_TYPE = Core.Const.EXPIRATION_TYPE

export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {
        schoolId: {
            type: [Number,String],
            default: 0
        },
        classId: {
            type: [Number,String],
            default: 0
        },
        orgId: {
            type: [Number,String],
            default: 0
        },
    },
    data() {
        return {
            EXPIRATION_TYPE,

            total: 0,
            currPage: 1,
            tableData: [],

            studentList: [],
            search: {
                student_id: '',
            },

            dialogVisible: false,
            selectItem: {},
            selectIndex: 1,
            customTime: '',

            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now();
                },
                shortcuts: [{
                    text: '两天后',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(dayjs().endOf('date').valueOf() + 3600 * 1000 * 24 * 2);
                        picker.$emit('pick', date);
                    }
                }, {
                    text: '三天后',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(dayjs().endOf('date').valueOf() + 3600 * 1000 * 24 * 3);
                        picker.$emit('pick', date);
                    }
                }, {
                    text: '六天后',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(dayjs().endOf('date').valueOf() + 3600 * 1000 * 24 * 6);
                        picker.$emit('pick', date);
                    }
                }, {
                    text: '七天后',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(dayjs().endOf('date').valueOf() + 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', date);
                    }
                }]
            },
        };
    },
    filters: {
        expirationTypeFilter(val) {
            switch (val) {
                case EXPIRATION_TYPE.UNSUBSCRIBED: return '未订阅'
                case EXPIRATION_TYPE.SUBSCRIBED: return '订阅'
                case EXPIRATION_TYPE.OVERDUE: return '到期'
                default: return '-'
            }
        }
    },
    created() {
        this.getTableData();

        this.getAllStudentList();
    },
    methods: {
        auth: Core.Utils.auth,
        pageChange(page) { // 学校教师列表页码改变
            this.currPage = page;
            this.getTableData();
        },
        getTableData() {
            Core.Api.User.listByClass(
                this.currPage,
                10,
                this.schoolId,
                this.classId,
                this.search.student_id
            ).then(res => {
                console.log('getTableData res', res)
                res.list.forEach(item => {
                    let time = item.user.expiration_time
                    if (time > 0) {
                        item.expirationType = (dayjs().unix() - time) < 0 ? EXPIRATION_TYPE.SUBSCRIBED : EXPIRATION_TYPE.OVERDUE
                    } else {
                        item.expirationType = EXPIRATION_TYPE.UNSUBSCRIBED
                    }
                })
                this.tableData = res.list
                this.total = res.count
            });
        },
        async getAllStudentList() { // 获取学生列表
            let page = 1
            let list = []

            while (true) {
                let res = await Core.Api.Student.list(page++, 20, this.orgId, this.schoolId, this.classId, 0)
                if (!res.list.length) {
                    break;
                }
                list.push(...res.list)
            }
            console.log('getAllStudentList list:', list)
            this.studentList = list
        },

        handleDialogShow(user) {
            this.selectItem = user
            this.dialogVisible = true
        },
        handleDialogClose() {
            this.dialogVisible = false
            this.selectIndex = 1
            this.customTime = ''
        },
        async handleDialogConfirm() {
            let days = 24 * 60 * 60
            switch (this.selectIndex) {
                case 1:
                    days = days * 30;
                    break;
                case 2:
                    days = days * 92;
                    break;
                case 3:
                    days = days * 365;
                    break;
                case 4:
                    if (!this.customTime) {
                        return this.$message.warning('请选择到期时间')
                    }
                    days = Math.round((this.customTime - dayjs().valueOf()) / 1000);
                    console.log('this.customTime:', this.customTime)
                    break;
            }
            console.log('handleDialogConfirm days:', days)
            Core.Api.User.addExpireTime(this.selectItem.id, days).then(res => {
                console.log('增加成功', res)
                this.getTableData()
                this.handleDialogClose()
            });
        },

        handleStop(data) { // 停止
            this.$confirm(`停止订阅并不会退还家长支付金额`, `确定要停止 ${data.user.name || (data.student.name + relationFilter(data.relation))} 使用吗?`, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.User.forceClose(data.user.id).then(() => {
                    this.$message.success('操作成功')
                    this.getTableData()
                });
            });
        },
        handleRefund(data) { // 退款
            if (data.expirationType !== EXPIRATION_TYPE.SUBSCRIBED) {
                return this.$message.warning("未订阅或已过期")
            }
            this.$confirm(`确定要退款 ${data.student.name} 最后一笔订单吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.User.refundLastOrder(data.user.id).then((res) => {
                    console.log('终止并退款', res)
                    setTimeout(() => {
                        this.$message('退款成功')
                        this.getTableData()
                    }, 3000)
                });
            });
        },
        handleOpenChange(data) { // 是否开放
            Core.Api.Student.setCameraAuthority(data.id).then(() => {
                this.$message.success("修改成功")
                this.getTableData()
            });
        },
        song3tian(data) { // 是否开放
            console.log('data', data);
            data.flag_is_give = 1
            Core.Api.User.addExpireTime3(data.id, data.user.id).then(res => {
                console.log('增加成功', res)
                this.$message.success("赠送成功")
                this.getTableData()
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .search-container {
        @include flex(row, space-between, center);
    }
}
.dialog-content {
    .vip-list {
        display: flex;
        justify-content: space-between;
        .vip-item {
            width: 80px;
            padding: 12px 10px;
            border-radius: 4px;
            border: 1px solid #f5f2f5;
            text-align: center;
            // margin-right: 15px;
            position: relative;
            cursor: pointer;
            &.active {
                border: 1px solid #1D517E;
            }
            .vip-tui {
                position: absolute;
                top: 0;
                right: 0;
                transform: translateY(-50%);
                width: 34px;
                height: 16px;
                line-height: 16px;
                background: #1D517E;
                border-radius: 0 8px 0 8px;
                font-size: 12px;
                font-weight: 400;
                color: #ffffff;
                text-align: center;
            }
            .item-title {
                font-size: 14px;
                font-weight: 500;
                color: #3f4549;
            }
            .item-money {
                font-weight: 500;
                color: #1D517E;
                font-size: 22px;
                margin-top: 3px;
                .unit {
                    font-size: 14px;
                }
            }
            .item-test {
                font-size: 12px;
                font-weight: 400;
                color: #9a9a9a;
                line-height: 17px;
                margin-top: 10px;
            }
        }
    }
}
</style>

